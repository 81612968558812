export const RouteConstants = {
  HOME: '/',
  LOGIN: '/login',
  REDIRECT: '/redirect',

  CONFIGURATIONS: '/configurations',
  EMAIL_TEMPLATES: '/email-templates',
  SMS_TEMPLATES: '/sms-templates',
  CARRIER_PRIORITY: '/carrier-priority',
  MS_PLAN_CARRIERS: '/configurations/ms-plan-carriers',
  MA_PLAN_CARRIERS: '/configurations/ma-plan-carriers',
  M_PART_D_PLAN_CARRIERS: '/configurations/m-part-d-plan-carriers',
  LEAD_MANAGEMENT: '/lead-management',
  ENROLLMENT_MANAGEMENT: '/enrollment-management'
};
