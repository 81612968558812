import { ApiUtils } from '../utils/api.utils';
import { EnrollmentManagementType } from '../types/data/enrollment-management.type';
import { MedicareQuoteType } from '../enums/medicare-quote-type.enum';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const postEnrollmentRule = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.POST_ENROLLMENT_RULES;
    axios
      .post(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateEnrollmentRule = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.POST_ENROLLMENT_RULES;
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const getEnrollmentRules = (type: MedicareQuoteType): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.GET_ENROLLMENT_RULES + `/${type}`;

    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => reject(err));
  });
};

const deleteEnrollmentRule = (
  id: string,
  type: MedicareQuoteType
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.DELETE_ENROLLMENT_RULES + `/${id}/${type}`;

    axios
      .delete(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => reject(err));
  });
};

const updatePriorities = (priority: {
  id: string;
  priority: number;
  existingPriority: number;
}): Promise<EnrollmentManagementType> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_ENROLLMENT_PRIORITIES;
    axios
      .patch(url, { priorities: priority }, ApiUtils.getHeaders())
      .then(response => {
        resolve(response.data.data);
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const cleanRuleData = (
  data: any,
  type: MedicareQuoteType,
  priority?: number
) => {
  const cleanedData: EnrollmentManagementType = {} as EnrollmentManagementType;
  cleanedData.id = data.id;
  cleanedData.name = data.name;
  cleanedData.priority = data.priority || priority;
  cleanedData.status = data.statusActive ? 'active' : 'inactive';
  cleanedData.carrierEnrollmentUrlMap = data.carrierEnrollmentUrlMap;
  cleanedData.statusActive = data.statusActive;
  cleanedData.threshold = data.threshold;
  cleanedData.quoteType = type;

  return cleanedData;
};

export const EnrollmentManagementService = {
  postEnrollmentRule,
  updateEnrollmentRule,
  getEnrollmentRules,
  deleteEnrollmentRule,
  cleanRuleData,
  updatePriorities
};
