import { Button, Typography, message } from 'antd';
import React, { useState } from 'react';

import { ConfigurationService } from '../../service/configuration.service';
import EditEnrollmentManagementModal from '../../modals/enrollment-management';
import EnrollmentManagementComponent from '../../components/enrollment-management';
import { EnrollmentManagementService } from '../../service/enrollment-management.service';
import { EnrollmentManagementType } from '../../types/data/enrollment-management.type';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import { MedicareQuoteType } from '../../enums/medicare-quote-type.enum';
import PriorityConfirmationModal from '../../modals/lead-management/priorityConfirmation';
import RemoveConfirmationModal from '../../modals/lead-management/remove';
import SecureComponent from '../../components/authorization';
import { Select } from 'antd/lib';
import orderBy from 'lodash.orderby';

const EnrollmentManagement: React.FC<IsAdminProp> = () => {
  const [addRuleModalVisible, setAddRuleModalVisible] = useState(false);
  const [selectedType, setSelectedType] = React.useState<MedicareQuoteType>(
    MedicareQuoteType['Medicare Supplement']
  );
  const [ruleInFocus, setRuleInFocus] =
    useState<EnrollmentManagementType | null>(null);
  const [rulesData, setRulesData] = useState<EnrollmentManagementType[]>([]);
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [isPrioritySaved, setIsPrioritySaved] = useState(true);
  const [showPriorityConfirmationModal, setShowPriorityConfirmationModal] =
    useState(false);
  const [priorityChangeConfirmation, setPriorityChangeConfirmation] =
    useState(false);
  const [currentEditData, setCurrentEditData] =
    useState<EnrollmentManagementType>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [
    deleteRuleConfirmationModalVisible,
    setDeleteRuleConfirmationModalVisible
  ] = useState(false);
  const [carriers, setCarriers] = React.useState<
    { name: string; naic: number }[]
  >([]);

  const handleRowSelection = (rows: string[]) => {
    setSelectedRows(rows);
  };

  const handleRuleEdit = (e: any) => {
    setRuleInFocus(e);
    setAddRuleModalVisible(true);
  };

  const handleRuleDelete = (e: any) => {
    setRuleInFocus(e);
    setDeleteRuleConfirmationModalVisible(true);
  };

  const getRulesData = () => {
    EnrollmentManagementService.getEnrollmentRules(selectedType)
      .then(res => {
        const rulesData = res.map((rule: EnrollmentManagementType) => {
          return {
            ...rule,
            statusActive: rule.status === 'active'
          };
        });
        setRulesData(rulesData);
      })
      .catch(() => {
        message.error(
          'Failed to fetch Enrollment management rules, please try again later'
        );
      })
      .finally(() => {
        setIsDataFetching(false);
      });
  };

  const editRule = (cleanedData: any) => {
    setIsDataFetching(true);
    setAddRuleModalVisible(false);
    EnrollmentManagementService.updateEnrollmentRule(cleanedData)
      .then(res => {
        const tempRulesData = rulesData.map(ruleData => {
          if (ruleData.id === cleanedData.id) {
            cleanedData.carrierEnrollmentUrlMap = res.carrierEnrollmentUrlMap;
            return cleanedData;
          }
          return ruleData;
        });
        setRulesData(() => tempRulesData);
        message.success('Rule successfully updated');
      })
      .catch(() => {
        message.error(`Failed to update the rule, please try again later`);
      })
      .finally(() => {
        getRulesData();
        setPriorityChangeConfirmation(false);
      });
  };

  const addUpdateRule = async (data: any) => {
    const cleanedData = EnrollmentManagementService.cleanRuleData(
      data,
      selectedType,
      rulesData.length + 1
    );
    if (cleanedData.id) {
      setCurrentEditData(cleanedData);
      let priorityChanged = false;
      if (!priorityChangeConfirmation)
        rulesData.forEach(rule => {
          if (
            rule.id === cleanedData.id &&
            rule.priority !== cleanedData.priority
          )
            priorityChanged = true;
        });
      if (priorityChanged) setShowPriorityConfirmationModal(true);
      else editRule(cleanedData);
    } else {
      setIsDataFetching(true);
      setAddRuleModalVisible(false);
      EnrollmentManagementService.postEnrollmentRule(cleanedData)
        .then(res => {
          const updatedRulesData = rulesData.concat({
            ...res,
            statusActive: res.status === "active" ? true : false
          });
          setRulesData(updatedRulesData);
          message.success('Rule successfully added');
        })
        .catch(() => {
          message.error(`Failed to add rule, please try again later`);
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  };

  const deleteRule = () => {
    setIsDataFetching(true);
    setDeleteRuleConfirmationModalVisible(false);
    if (ruleInFocus && ruleInFocus.id) {
      EnrollmentManagementService.deleteEnrollmentRule(
        ruleInFocus.id,
        selectedType
      )
        .then(res => {
          console.log(res);
          const tempRulesData = rulesData.filter(
            rule => rule.id !== ruleInFocus.id
          );
          setRulesData(tempRulesData);
          message.success(`Rule: ${ruleInFocus.name} deleted successfully`);
        })
        .catch(() => {
          message.error(
            `Failed to delete rule: ${ruleInFocus.name}, please try again later`
          );
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  };

  const handlePriorityConfirmation = () => {
    setPriorityChangeConfirmation(true);
    setShowPriorityConfirmationModal(false);
    editRule(currentEditData);
  };

  const handleDragAndDrop = (fromIndex: any, toIndex: any) => {
    let data = rulesData;

    const from = fromIndex - 1;
    const to = toIndex - 1;

    const elm = data.splice(from, 1)[0];
    data.splice(to, 0, elm);

    data = data.map((rule, index) => {
      return {
        ...rule,
        priority: index + 1
      };
    });
    setRulesData(() => [...data]);
    setIsPrioritySaved(false);
    EnrollmentManagementService.updatePriorities({
      id: elm.id,
      priority: toIndex,
      existingPriority: fromIndex
    })
      .then(() => {
        setIsPrioritySaved(true);
      })
      .catch(() => {
        message.error('Failed to update priority, please try again later');
        data = orderBy(data, 'priority', 'asc');
        setIsPrioritySaved(true);
      });
  };

  const fetchCarriers = async (planType: MedicareQuoteType) => {
    setIsDataFetching(true);
    switch (planType) {
      case MedicareQuoteType['Medicare Supplement']:
        {
          const data = await ConfigurationService.getAllMSCarriers();
          setCarriers(data);
        }
        break;
      case MedicareQuoteType['Medicare Advantage']:
        {
          const data = await ConfigurationService.getAllMNSCarriers({
            'quote-type': MedicareQuoteType['Medicare Advantage']
          });
          setCarriers(data);
        }
        break;
      case MedicareQuoteType['Medicare Prescription']:
        {
          const data = await ConfigurationService.getAllMNSCarriers({
            'quote-type': MedicareQuoteType['Medicare Prescription']
          });
          setCarriers(data);
        }
        break;
      default:
        setCarriers([]);
        break;
    }
    setIsDataFetching(false);
  };

  React.useEffect(() => {
    getRulesData();
    fetchCarriers(selectedType);
  }, [selectedType]);

  return (
    <>
      <Helmet>
        <title>
          Enrollment Management | Quoting Tool SA Dashboard | Elite Insurance
          Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Lead Management`}
        />
      </Helmet>

      {addRuleModalVisible && (
        <EditEnrollmentManagementModal
          carriers={carriers}
          data={ruleInFocus}
          rulesData={rulesData}
          handleOk={addUpdateRule}
          handleCancel={() => {
            setAddRuleModalVisible(false);
          }}
        />
      )}

      {!addRuleModalVisible && (
        <>
          <div>
            <div>
              <Typography.Title level={3}>
                Enrollment Management
              </Typography.Title>
            </div>
            <div
              style={{
                background: '#ffffff',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px',
                margin: '20px 0px'
              }}>
              <div>
                <Typography.Text strong style={{ paddingRight: '20px' }}>
                  Type
                </Typography.Text>
                <Select
                  style={{ minWidth: '200px' }}
                  value={selectedType}
                  onChange={value => setSelectedType(value)}>
                  {Object.values(MedicareQuoteType).map((operator, index) => {
                    return (
                      <Select.Option key={index} value={operator}>
                        {Object.keys(MedicareQuoteType)[index]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <Button type='primary' onClick={handleRuleEdit} disabled={false}>
                + Add new config
              </Button>
            </div>
          </div>
          <EnrollmentManagementComponent
            selectedRows={selectedRows}
            handleRowSelection={handleRowSelection}
            data={rulesData}
            handleEdit={handleRuleEdit}
            handleRemove={handleRuleDelete}
            handleDragAndDrop={handleDragAndDrop}
            disabled={selectedRows.length > 1}
            loading={!isPrioritySaved}
            isDataFetching={isDataFetching}
          />
        </>
      )}
      <RemoveConfirmationModal
        visible={deleteRuleConfirmationModalVisible}
        title={'Want to delete the Row?'}
        handleOk={deleteRule}
        handleCancel={() => {
          setDeleteRuleConfirmationModalVisible(false);
        }}
      />
      <PriorityConfirmationModal
        visible={showPriorityConfirmationModal}
        handleOk={() => {
          handlePriorityConfirmation();
        }}
        handleCancel={() => {
          setShowPriorityConfirmationModal(false);
        }}
      />
    </>
  );
};

export default SecureComponent(EnrollmentManagement, false);
