import { EnrollmentManagementType } from '../../types/data/enrollment-management.type';
import TableComponent from '../table';
import { TableType } from '../../enums/table-type.enum';

type ConfigurationProps = {
  data?: EnrollmentManagementType[];
  loading?: boolean;
  disabled?: boolean;
  handleEdit?: any;
  handleRemove?: any;
  handleDragAndDrop: any;
  isDataFetching?: boolean;
  selectedRows?: string[];
  handleRowSelection?: (rows: string[]) => void;
};
const EnrollmentManagementComponent = (props: ConfigurationProps) => {
  return (
    <TableComponent
      handleSelect={props.handleRowSelection}
      selectedRowKeys={props.selectedRows}
      // selection
      pagination={false}
      handleEdit={props.handleEdit}
      handleRemove={props.handleRemove}
      disabled={props.disabled}
      loading={props.loading}
      data={props.data}
      tableType={TableType.ENROLLMENT_MANAGEMENT}
      handleDragAndDrop={props.handleDragAndDrop}
      isDataFetching={props.isDataFetching}
    />
  );
};

export default EnrollmentManagementComponent;
