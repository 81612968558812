const baseURL = process.env.REACT_APP_BACKEND_BASE_URL + '/api/v1/super-admin';

export const UrlConstants = {
  // REPORT APIs
  UPDATE_REPORT_BY_ID: baseURL + '/report/:reportId',

  // Configuration APIs
  GET_ALL_CONFIGURATIONS: baseURL + '/configs',
  REFRESH_CONFIGURATIONS: baseURL + '/configs/refresh',
  UPDATE_CONFIG_BY_ID: baseURL + '/configs/:configId',

  // Lead Management APIs
  GET_RULES: baseURL + '/rules',
  DELETE_RULE: baseURL + '/rule/:ruleId',
  GET_SF_FIELDS: baseURL + '/salesforce-fields',
  ADD_RULE: baseURL + '/rule',
  UPDATE_RULE: baseURL + '/rule/:ruleId',
  BULK_UPDATE_RULE: baseURL + '/rule/bulk-update',
  UPDATE_PRIORITIES: baseURL + '/priority',

  // Email Templates
  GET_ALL_EMAIL_TEMPLATES: baseURL + '/notification-template/email',
  UPDATE_EMAIL_TEMPLATE: baseURL + '/notification-template/email',

  // SMS Templates
  GET_ALL_SMS_TEMPLATES: baseURL + '/notification-template/sms',
  UPDATE_SMS_TEMPLATE: baseURL + '/notification-template/sms',

  //Upload Excel
  GET_MASTER_DATA: baseURL + '/master-data',
  GET_SAMPLE_DATA: baseURL + '/sample-data',
  UPLOAD_RANK_OBJECTS: baseURL + '/quotes/rank',
  DOWNLOAD_RANK_OBJECT: baseURL + '/quotes/rank',

  GET_CARRIERS_OF_MS_PLANS: baseURL + '/ms-plan/carriers',
  UPDATE_CARRIERS_OF_MS_PLANS: baseURL + '/ms-plan/carriers/:configId',

  GET_CARRIERS_OF_MNS_PLANS: baseURL + '/mns-plan/carriers',
  UPDATE_CARRIERS_OF_MNS_PLANS: baseURL + '/mns-plan/carriers/:configId',

  POST_ENROLLMENT_RULES: baseURL + '/enrollment-rules',
  PATCH_ENROLLMENT_RULES: baseURL + '/enrollment-rules',
  GET_ENROLLMENT_RULES: baseURL + '/enrollment-rules',
  DELETE_ENROLLMENT_RULES: baseURL + '/enrollment-rules',
  UPDATE_ENROLLMENT_PRIORITIES: baseURL + '/enrollment-rules/priority'
};
