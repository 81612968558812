import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { EnrollmentManagementType } from '../../types/data/enrollment-management.type';

/**
 * get columns of reports
 * @returns {ColumnsType<LeadManagementType>}
 */
export const getEnrollmentManagementColumns = (
  handleEdit: any,
  handleRemove: any,
  disabled = false
): ColumnsType<EnrollmentManagementType> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',

    render: name => <span style={{ textTransform: 'capitalize' }}>{name}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',

    sorter: (a, b) => a.status.localeCompare(b.status),
    align: 'center',
    render: status => (
      <span style={{ textTransform: 'capitalize' }}>{status}</span>
    )
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',

    sorter: (a, b) => a.status.localeCompare(b.status),
    align: 'center',
    render: status => (
      <span style={{ textTransform: 'capitalize' }}>{status}</span>
    )
  },
  {
    title: 'Threshold',
    dataIndex: 'threshold',
    key: 'threshold',

    sorter: (a, b) => a.status.localeCompare(b.status),
    align: 'center',
    render: status => (
      <span style={{ textTransform: 'capitalize' }}>{status}</span>
    )
  },
  {
    title: '',
    key: 'id',
    render: data => (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gridGap: '5px',
          justifyContent: 'center'
        }}>
        <Button
          type='primary'
          disabled={disabled}
          onClick={() => handleEdit(data)}>
          <EditOutlined /> Edit
        </Button>
        <Button
          style={{
            backgroundColor: '#FF4D4F',
            color: disabled ? '#00000025' : 'white'
          }}
          type='default'
          disabled={disabled}
          onClick={() => handleRemove(data)}>
          <DeleteOutlined /> Remove
        </Button>
      </div>
    ),
    align: 'center'
  }
];
