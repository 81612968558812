import { Button, Layout, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import EIPLogo from '../../assets/images/eip-logo.png';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import React from 'react';
import { RouteConstants } from '../../constants/route.constants';
import { useMsal } from '@azure/msal-react';

const getMenuItems = (): MenuProps['items'] => {
  const menuItems = [
    {
      label: <Link to={RouteConstants.CONFIGURATIONS}>Configurations</Link>,
      key: 'configurations'
    },
    {
      label: <Link to={RouteConstants.EMAIL_TEMPLATES}>Email Templates</Link>,
      key: 'email-templates'
    },
    {
      label: <Link to={RouteConstants.SMS_TEMPLATES}>SMS Templates</Link>,
      key: 'sms-templates'
    },
    {
      label: <Link to={RouteConstants.CARRIER_PRIORITY}>Carrier Priority</Link>,
      key: 'carrier-priority'
    },
    {
      label: <Link to={RouteConstants.LEAD_MANAGEMENT}>Lead Management</Link>,
      key: 'lead-management'
    },
    {
      label: (
        <Link to={RouteConstants.ENROLLMENT_MANAGEMENT}>
          Enrollment Management
        </Link>
      ),
      key: 'enrollment-management'
    }
  ];

  return menuItems;
};

const Header: React.FC<IsAdminProp> = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const currentPath = location.pathname.split('/').filter(n => n);

  return (
    <Layout.Header
      style={{
        position: 'fixed',
        width: '100%',
        zIndex: 100,
        background: '#fff'
      }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className='logo'>
          <Link to={RouteConstants.CONFIGURATIONS}>
            <img src={EIPLogo} />
          </Link>
        </div>
        <Menu
          mode='horizontal'
          defaultSelectedKeys={[currentPath[0]]}
          items={getMenuItems()}
          style={{ flex: 1 }}
        />
        <Button
          style={{ margin: 'auto' }}
          onClick={() => {
            instance.logoutRedirect();
          }}>
          Logout
        </Button>
      </div>
    </Layout.Header>
  );
};

export default Header;
