import { Spin, Table } from 'antd';
import {
  getEmailTemplateColumns,
  getSmsTemplateColumns
} from '../../utils/table-managers/templates.table-manager';

import type { ColumnsType } from 'antd/lib/table';
import ReactDragListView from 'react-drag-listview';
import { TableType } from '../../enums/table-type.enum';
import { getConfigurationColumns } from '../../utils/table-managers/configurations.table-manager';
import { getEnrollmentManagementColumns } from '../../utils/table-managers/enrollement-management-table-manager';
import { getLeadManagementColumns } from '../../utils/table-managers/lead-management.table-manager';
import { getMNSPlanCarrierColumns } from '../../utils/table-managers/mns-plan-carriers.table-manager';
import { getMSPlanCarrierColumns } from '../../utils/table-managers/ms-plan-carriers.table-manager';
import isEmpty from 'lodash.isempty';
import { useState } from 'react';

type TableComponentProps = {
  data?: any;
  tableType: TableType;
  loading?: boolean;
  disabled?: boolean;
  handleEdit?: any;
  handleSelect?: any;
  handleRemove?: any;
  selection?: boolean;
  selectedRowKeys?: React.Key[];
  handleDragAndDrop?: (fromIndex: any, toIndex: any) => void;
  pagination?: boolean;
  isDataFetching?: boolean;
};

const TableComponent = (props: TableComponentProps) => {
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

  let columns: ColumnsType<any> | null = null;
  if (props.tableType === TableType.CONFIGURATIONS)
    columns = getConfigurationColumns(props.handleEdit, props.disabled);
  else if (props.tableType === TableType.EMAIL_TEMPLATES)
    columns = getEmailTemplateColumns(props.handleEdit);
  else if (props.tableType === TableType.SMS_TEMPLATES)
    columns = getSmsTemplateColumns(props.handleEdit);
  else if (props.tableType === TableType.MS_PLAN_CARRIERS)
    columns = getMSPlanCarrierColumns();
  else if (props.tableType === TableType.MNS_PLAN_CARRIERS)
    columns = getMNSPlanCarrierColumns();
  else if (props.tableType === TableType.LEAD_MANAGEMENT)
    columns = getLeadManagementColumns(
      props.handleEdit,
      props.handleRemove,
      props.disabled
    );
  else if (props.tableType === TableType.ENROLLMENT_MANAGEMENT)
    columns = columns = getEnrollmentManagementColumns(
      props.handleEdit,
      props.handleRemove,
      props.disabled
    );
  if (columns)
    return (
      <>
        {props.handleDragAndDrop ? (
          <ReactDragListView onDragEnd={props.handleDragAndDrop as any}>
            <Table
              rowSelection={
                props.selection
                  ? {
                      selectedRowKeys: !props.selectedRowKeys
                        ? selectedRows
                        : props.selectedRowKeys,
                      onChange(selectedRowKeys) {
                        setSelectedRows(selectedRowKeys);
                        props.handleSelect(selectedRowKeys);
                      }
                    }
                  : undefined
              }
              rowClassName={() => 'draggable-row'}
              loading={props.loading || props.isDataFetching}
              columns={columns}
              pagination={
                props.pagination && !props.selection
                  ? {
                      defaultPageSize: 20,
                      total: props.data.length,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                      pageSizeOptions: [20, 50, 100, 200],
                      showSizeChanger: true
                    }
                  : false
              }
              rowKey={row => {
                if (!isEmpty(row.id)) return row.id;
                else if (!isEmpty(row.naic)) return row.naic;
                else return JSON.stringify(row);
              }}
              dataSource={props.data}
              bordered
              scroll={!props.selection ? { x: 'fit-content' } : { y: 400 }}
            />
          </ReactDragListView>
        ) : (
          <Table
            rowSelection={
              props.selection
                ? {
                    selectedRowKeys: !props.selectedRowKeys
                      ? selectedRows
                      : props.selectedRowKeys,
                    onChange(selectedRowKeys) {
                      setSelectedRows(selectedRowKeys);
                      props.handleSelect(selectedRowKeys);
                    }
                  }
                : undefined
            }
            loading={props.loading}
            columns={columns}
            pagination={
              !props.selection
                ? {
                    defaultPageSize: 20,
                    total: props.data.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    pageSizeOptions: [20, 50, 100, 200],
                    showSizeChanger: true
                  }
                : false
            }
            rowKey={row => {
              if (!isEmpty(row.id)) return row.id;
              else if (!isEmpty(row.naic)) return row.naic;
              else return JSON.stringify(row);
            }}
            dataSource={props.data}
            bordered
            scroll={!props.selection ? { x: 'fit-content' } : { y: 400 }}
          />
        )}
      </>
    );
  else return <Spin />;
};

export default TableComponent;
