import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Switch,
  Tooltip,
  Typography,
  message
} from 'antd';

import { EnrollmentManagementType } from '../../types/data/enrollment-management.type';
import { PageHeader } from '@ant-design/pro-layout';
import { createRef } from 'react';

interface EditEnrollmentManagementProps {
  handleOk: (data: EnrollmentManagementType) => void;
  handleCancel: (event: any) => void;
  data: EnrollmentManagementType | null;
  rulesData: EnrollmentManagementType[];
  carriers: { name: string; naic: number }[];
}

const EditEnrollmentManagementModal = (
  props: EditEnrollmentManagementProps
) => {
  const formModalRef: React.RefObject<FormInstance> = createRef<FormInstance>();

  const ruleNames = props.rulesData.map(rule => rule.name.toLowerCase());
  const rulePriorities = props.rulesData.map(rule => rule.priority);

  function restrictToNumericInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (
      !/^\d$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight'
    ) {
      e.preventDefault();
    }
  }

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then((data: EnrollmentManagementType) => {
        console.log(data);
        const dataToBeSubmitted = { ...props.data, ...data };
        props.handleOk(dataToBeSubmitted);
      })
      .catch(() => {
        message.warning('Fill all the required fields.');
      });
  };

  return (
    <PageHeader
      className='space-top-10'
      ghost={false}
      title={
        <>
          <ArrowLeftOutlined
            style={{ marginRight: '5px' }}
            onClick={props.handleCancel}
          />
          {props.data?.id ? 'Edit Rule' : 'Add New Rule'}
        </>
      }
      style={{ background: '#ffffff' }}>
      <Form
        ref={formModalRef}
        initialValues={{ statusActive: false, ...props.data }}>
        <div style={{ display: 'flex', gap: '20px' }}>
          <Form.Item
            name='name'
            label='Rule Name'
            rules={[
              {
                required: true,
                message: 'Please provide a valid value'
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (
                    ruleNames.includes(value.toLowerCase()) &&
                    !(props.data && props.data.id)
                  ) {
                    return Promise.reject('This rule name already exists.');
                  } else if (
                    ruleNames.includes(value.toLowerCase()) &&
                    props.data &&
                    props.data.id &&
                    value.toLowerCase() !== props.data.name.toLowerCase()
                  ) {
                    return Promise.reject('This rule name already exists.');
                  } else {
                    return Promise.resolve();
                  }
                }
              })
            ]}>
            <Input.TextArea
              rows={1}
              style={{ resize: 'none' }}
              disabled={props.data?.id ? true : false}
            />
          </Form.Item>
          <Form.Item
            name='priority'
            label='Priority'
            rules={[
              {
                required: false,
                message: 'Please provide a valid value'
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (
                    rulePriorities.includes(value) &&
                    !(props.data && props.data.id)
                  ) {
                    return Promise.reject('This priority already exists.');
                  } else {
                    return Promise.resolve();
                  }
                }
              })
            ]}>
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              onKeyDown={restrictToNumericInput}
            />
          </Form.Item>

          <Form.Item name='statusActive' label='Active' valuePropName='checked'>
            <Switch style={{ flex: 1, marginRight: '10px', width: '10%' }} />
          </Form.Item>
        </div>
        <Divider />
        <div>
          <Form.Item>
            <Form.List name='carrierEnrollmentUrlMap' initialValue={['']}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '5px',
                        marginTop: '-5px',
                        marginLeft: '20px'
                      }}>
                      <Typography.Title level={5}>
                        Carrier Enrollment URL Map
                      </Typography.Title>

                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ marginLeft: 'auto' }}>
                        <PlusOutlined /> Add Condition
                      </Button>
                    </div>

                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          width: '80%',
                          display: 'flex',
                          flexDirection: 'row',
                          marginLeft: '20px',
                          marginTop: '5px'
                        }}>
                        <div style={{ flex: '1 1 30%', marginRight: '10px' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'carriers']}
                            label='Carriers'
                            rules={[
                              {
                                required: true,
                                message: 'Please provide a valid value'
                              }
                            ]}>
                            <Select
                              allowClear
                              showSearch
                              mode='multiple'
                              filterOption={(input, option) => {
                                if (option?.children) {
                                  return option.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                } else {
                                  return false;
                                }
                              }}>
                              {props.carriers.map((operator, index) => {
                                return (
                                  <Select.Option
                                    key={index}
                                    value={operator.naic}>
                                    {`${operator.name} (${operator.naic})`}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ flex: '1 1 25%', marginRight: '10px' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'url']}
                            label='URL'
                            rules={[
                              {
                                required: true,
                                message: 'Please provide a valid value'
                              }
                            ]}>
                            <Input.TextArea
                              rows={1}
                              style={{ resize: 'none' }}
                            />
                          </Form.Item>
                        </div>

                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            style={{
                              color: 'red',
                              fontSize: '20px',
                              marginBottom: '20px'
                            }}
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </div>
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '5px',
            marginTop: '-5px',
            marginLeft: '20px'
          }}>
          <Typography.Title level={5}>Threshold</Typography.Title>
          <Tooltip title="Define the percentage of leads whose users' journey should follow the rule defined.">
            <QuestionCircleOutlined
              style={{
                color: '#00000073',
                marginLeft: '5px',
                marginBottom: '5px'
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '-25px',
            marginLeft: '20px'
          }}>
          <Form.Item
            name='threshold'
            label='Percentage'
            rules={[
              {
                required: true,
                message: 'Please provide a valid value'
              }
            ]}>
            <InputNumber min={0} max={100} onKeyDown={restrictToNumericInput} />
          </Form.Item>
        </div>
        <Divider />
        <div>
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={props.handleCancel}>Cancel</Button>
            <Button
              type='primary'
              onClick={handleFormSubmit}
              style={{ marginLeft: '10px' }}>
              {props.data && props.data.id ? 'Update Rule' : 'Add New Rule'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </PageHeader>
  );
};

export default EditEnrollmentManagementModal;
